import request from "@/utils/request";
import Cookies from "js-cookie";
// 获取审批分组
export function taskPage(params) {
  return request({
    url: "/process-apply-info/taskPage",
    method: "get",
    params: params,
  });
}

// 获取审批节点
export function schedule(data) {
  return request({
    url: "/process-apply-info/schedule",
    method: "post",
    data: data,
  });
}

// 获取审批详情
export function queryProcessConfigInfo(params) {
  return request({
    url: "/process-config-info/queryProcessConfigInfo",
    method: "get",
    params: params,
  });
}

// 申请流程
export function apply(data) {
  return request({
    url: `/process-apply-info/apply`,
    method: "POST",
    data,
  });
}

// 拒绝任务
export function stop(data) {
  return request({
    url: `/process-apply-info/stop`,
    method: "POST",
    data,
  });
}
// 同意任务
export function complete(data) {
  return request({
    url: `/process-apply-info/complete`,
    method: "POST",
    data,
  });
}

// 撤销申请
export function cancelProcess(data) {
  return request({
    url: `/process-apply-info/cancelProcess`,
    method: "post",
    data,
  });
}

// 任务列表数量统计
export function taskNumCount(params) {
  return request({
    url: `/process-apply-info/taskNumCount`,
    method: "get",
    params,
  });
}
//上传图片
export function uploadFile(data) {
  return request({
    url: "/cdn/upload/uploadFile",
    method: "POST",
    headers: {
      // Authorization: localStorage.getItem("Authorization_OA_H5"),
      Authorization:localStorage.getItem("Authorization_OA_H5"),

      "User-Source": "APP_OA",
    },
    data,
  });
}
// 审批流程添加评论
export function addComment(data) {
  return request({
    url: `/process-apply-info/addComment`,
    method: "POST",
    data,
  });
}

//删除表单申请草稿信息
export function delApplyTemp(params) {
  return request({
    url: `/process-apply-info/delApplyTemp`,
    method: "GET",
    params,
  });
}
//查询表单申请草稿信息
export function getApplyTemp(params) {
  return request({
    url: `/process-apply-info/getApplyTemp`,
    method: "GET",
    params,
  });
}
//保存表单申请草稿信息
export function saveTemp(data) {
  return request({
    url: `/process-apply-info/saveTemp`,
    method: "POST",
    data,
  });
}
