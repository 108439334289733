import request from "@/utils/request";
import Cookies from "js-cookie";
/*
 * 供应商详情查询
 */
export function purchaseInfo(params) {
  return request({
    url: `/purchase/info/${params.puCode}`,
    method: "GET",
    params,
  });
}
/*
 * 审批详情查询
 */
export function purchaseApproveInfo(params) {
  return request({
    url: `/purchase/approveInfo/${params.puCode}`,
    method: "GET",
    params,
  });
}
